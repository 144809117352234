  /* 进入切页动画，可根据开发这需要自由开发 */
  .forward-enter-active, .forward-leave-active {
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s;
    z-index: 0;
  }
  .forward-leave {
    transform: translate3d(0, 0, 0);
  }
  .forward-leave-to {
    transform: translate3d(-100%, 0, 0);
  }
  .forward-enter {
    transform: translate3d(100%, 0, 0);
  }
  .forward-enter-to {
    transform: translate3d(0, 0, 0);
  }

  /* 离开切页动画，可根据开发这需要自由开发 */
  .backward-enter-active, .backward-leave-active {
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s;
    z-index: 0;
  }
  .backward-leave {
    transform: translate3d(0, 0, 0);
  }
  .backward-leave-to {
    transform: translate3d(100%, 0, 0);
  }
  .backward-enter {
    transform: translate3d(-100%, 0, 0);
  }
  .backward-enter-to {
    transform: translate3d(0, 0, 0);
  }